.detailsInvestidor {
    width: 100%;

}

.divTitleInvestidor {
    display: flex;
    flex-wrap: wrap;
    border-top: 5px solid #1a2f67;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 2%;
    margin-top: 5%;
    width: 90%;
    justify-content: center;
    overflow: auto; /* Remova max-height para permitir crescimento */
}



.investidorMessage1 {
    color: #1a2f67;
    font-size: 1.4rem;
}

.investidorTable {
    font-size: 1.2rem;
    width: 100%;
    table-layout: fixed; /* Adicionado para fixar a largura da tabela */
}
.investidorTable tr th,
.investidorTable tr td {
    border: 0px;
    background-color: white;
    text-align: center;
    padding: 10px;
    white-space: nowrap; /* Evitar que as palavras se quebrem */
    overflow: hidden;
    text-overflow: ellipsis; /* Adicionado para exibir "..." quando o texto ultrapassar a largura */
}

.investidorTable tbody {
    overflow: auto; /* Adicionado para permitir rolagem dentro do tbody */
    max-height: 400px; /* Altura máxima para exibir, ajuste conforme necessário */
}
.investidorTable2 {
    display: flex;
    width: 100%;
    justify-content: center;
}


.investidorTable tr th {
    color: #1a2f67;
}

.investidorTable td {
    color: #2e2e2e;
    font-size: 1.1rem;
}

.divButtonsAction {
    display: flex;
}

.tableIcons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.invesDelete,
.invesUpdt {
    cursor: pointer;
    color: #db0f0f;
    margin-right: 10px;
}


.buttonTd {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.investidorContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
}

.investidorField {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;
}

.invesP {
    padding: 5px;
    font-weight: bold;
    border-radius: 8px;
}

.invesP1 {
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 90%;
}

/* Estilos para o fundo escuro */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Estilos para o modal */
/* Estilos para o modal com sobreposição */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Estilos para centralizar o modal na tela */
.modal-content {
    position: relative;
    width: 60%;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
}

.modalClose {
    background-color: #fff;
    /* Fundo branco */
    color: #FF0000;
    /* Cor vermelha para o botão de fechar */
    font-size: 20px;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 20px;
}

.detailsInvestidor .searchForm{
    display: flex; 
    align-items: center; 
    width: 100%;
}
.detailsInvestidor .searchForm .inputSearch {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        padding-left: 3%;
        padding-right: 3%;
        width: 90%;
        height: 3rem;
        border: #c6c6c6 2px solid;
        border-radius: 8px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.detailsInvestidor .searchForm .btnClean {
    padding: 15px 15px;
    background-color: transparent;
    color: rgb(255, 0, 0);
    border: 1px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0px;
    margin-left: 10px;
    transform: 500ms;
    transition: 0.5s;
}