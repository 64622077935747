.garantiasDiv {
    width: 70%;
    margin-left: 23%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: flex-end;
    /*border: 2px solid #1a2f67;*/
    border-radius: 10px;
    padding: 20px;
}
.boxGarantia {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 2px solid #1a2f67;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.divInputsDocAvaG {
    display: flex;
    flex-direction: column;
    width: 50%;
   /* background-color: #4ac0b5;*/
}

.customFileInput2,
.documentField2 label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #1B2E67;
    border: #cfcfcf 2px solid;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-align: center;
    height: 46px;
    font-size: 18px;
    width: 92%;
  /*  width: 100%;*/
    margin-top: 20px;
    padding-left: 10px;
    justify-content: center;
   
}
.uploadText{
    text-align: center !important;
    align-items: center;
}
.garantiasBody {
    background-color: #fff;
    padding: 10px;

}
@media (min-width: 1800px) {
    .divInputsDocAvaG {
        width: 55%;
       /* background-color: #4ac0b5;*/
    }
   
}